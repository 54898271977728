import { CountryIsoCode } from '@toasttab/buffet-pui-country-utilities'
import { US } from './country/US'
import { IE } from './country/IE'
import { GB } from './country/GB'
import { CA } from './country/CA'
import { AU } from './country/AU'
import { NZ } from './country/NZ'

export type PhoneNumberFormats = {
  placeholder?: string
  format: (num: string) => string
  phoneNumberMinLength: (num: string) => number
  makeNationalNumberIntlCompatible?: (num: string) => string
  makeNationalNumberLocal?: (num: string) => string
}

export const phoneNumberFormatsByCountry = (
  country: CountryIsoCode
): PhoneNumberFormats => {
  switch (country) {
    case 'US':
      return US
    case 'IE':
      return IE
    case 'GB':
      return GB
    case 'CA':
      return CA
    case 'AU':
      return AU
    case 'NZ':
      return NZ
    default:
      return {
        placeholder: undefined,
        format: () => '',
        phoneNumberMinLength: () => 4 // Global minimum
      }
  }
}
