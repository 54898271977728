import { Currency } from '@toasttab/buffet-pui-types'

export { Currency }

export const currencies = {
  AU: Currency.AUD,
  CA: Currency.CAD,
  IE: Currency.EUR,
  JP: Currency.JPY,
  NZ: Currency.NZD,
  GB: Currency.GBP,
  US: Currency.USD
}
