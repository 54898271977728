export const diallingCodes = {
  AU: '61',
  CA: '1',
  IE: '353',
  NZ: '64',
  GB: '44',
  US: '1'
}

export type DiallingCodes = typeof diallingCodes
