import { PhoneNumberFormats } from '../phoneNumberFormatsByCountry'
import { removeLeadingZero, addLeadingZero } from '../utils'

export const AU: PhoneNumberFormats = {
  placeholder: '491 570 159',
  format: (num: string) => {
    if (num.startsWith('04')) return '#### ### ###'
    if (num.startsWith('4')) return '### ### ###'
    if (['02', '03', '07', '08'].some((prefix) => num.startsWith(prefix)))
      return '## #### ####'
    return '# #### ####'
  },
  phoneNumberMinLength: (num: string) => (num.startsWith('0') ? 10 : 9),
  makeNationalNumberIntlCompatible: removeLeadingZero,
  makeNationalNumberLocal: addLeadingZero
}
