import { PhoneNumberFormats } from '../phoneNumberFormatsByCountry'
import { startsWithNumber, removeLeadingZero, addLeadingZero } from '../utils'

function formatPhoneNumber(num: string): string {
  const defaultFormat = '### ### ####'
  if (!num) {
    return defaultFormat
  }
  if (num.startsWith('0818') || num.startsWith('18')) {
    return '#### ### ###'
  }
  if (startsWithNumber(num, /^(0[1-6]|9)/)) {
    // Special cases for Ireland landlines starting 0X but not 07 or 08
    if (num.startsWith('01')) {
      return '## ### ####'
    }
    if (
      num.startsWith('0402') ||
      num.startsWith('0404') ||
      num.startsWith('0504') ||
      num.startsWith('0505')
    ) {
      return '#### ######'
    }
    if (num.length === 8) {
      return '### ######'
    }
    return '### ### ####'
  }
  if (!num.startsWith('0')) {
    return '## ### ####'
  }
  return defaultFormat
}

export const IE: PhoneNumberFormats = {
  placeholder: '086 123 4567',
  format: formatPhoneNumber,
  phoneNumberMinLength: (num) => {
    if (num.startsWith('01')) {
      return 8
    } else if (num.startsWith('0')) {
      return 10
    }
    return 9
  },
  makeNationalNumberIntlCompatible: removeLeadingZero,
  makeNationalNumberLocal: addLeadingZero
}
