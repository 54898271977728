import { PhoneNumberFormats } from '../phoneNumberFormatsByCountry'
import { removeLeadingZero, addLeadingZero } from '../utils'

export const NZ: PhoneNumberFormats = {
  placeholder: '21 123 4567',
  format: (num: string) => {
    if (['03', '04', '06', '07', '09'].some((prefix) => num.startsWith(prefix)))
      return '## ### ####'
    if (['3', '4', '6', '7', '9'].some((prefix) => num.startsWith(prefix)))
      return '# ### ####'
    if (num.startsWith('2')) return '## ### ####'
    return num.startsWith('02') ? '### ### ####' : '# ### ####'
  },
  phoneNumberMinLength: (num: string) => {
    if (num.startsWith('02')) {
      return 10
    } else if (num.startsWith('2')) {
      return 9
    } else if (num.startsWith('0')) {
      return 9
    }
    return 8
  },
  makeNationalNumberIntlCompatible: removeLeadingZero,
  makeNationalNumberLocal: addLeadingZero
}
