import { PhoneNumberFormats } from '../phoneNumberFormatsByCountry'
import { removeLeadingZero, addLeadingZero } from '../utils'

export const GB: PhoneNumberFormats = {
  placeholder: '0777 123 4567',
  format: (num) => (num.startsWith('0') ? '#### ### ####' : '### ### ####'),
  phoneNumberMinLength: (num) => (num.startsWith('0') ? 10 : 9),
  makeNationalNumberIntlCompatible: removeLeadingZero,
  makeNationalNumberLocal: addLeadingZero
}
