//IMPORTANT: Do not move or remove the following import. It must be at the first import of this file. See https://www.npmjs.com/package/systemjs-webpack-interop#newer-versions-of-webpack
import 'systemjs-webpack-interop/auto-public-path'

import * as React from 'react'
import ReactDOM from 'react-dom'
import { banquetSingleSpaReact, BanquetProps } from 'banquet-runtime-modules'
import { ThemeManager } from '@toasttab/banquet-theme-manager'
import { themes } from '@local/themes'
import { App } from './app/components/App/App'
import './index.css'

import { loadTranslations } from '@local/translations'

const reactLifecycles = banquetSingleSpaReact({
  React,
  ReactDOM,
  cssScope: 'data-gfps',
  rootComponent: App,
  portalContainers: ['banquetPortalsContainer'],
  sentry: {
    publicKey: '8f92fdfbb27840d89223eee28e568037@o37442',
    projectId: '5828824',
    releaseVersion: process.env.PKG_VERSION
  },
  theme: new ThemeManager((props: BanquetProps) => {
    if (props.isNextBuffetTheme) {
      return themes.next
    }
    return themes.current
  })
})

export const bootstrap = [loadTranslations, ...reactLifecycles.bootstrap]
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'guest-feedback-public-spa'
