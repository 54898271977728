import React, { cloneElement, useEffect } from 'react'
import cx from 'classnames'
import { useUniqueId } from '@toasttab/buffet-utils'
import { TestIdentifiable, Labelled } from '@toasttab/buffet-shared-types'
import { Label } from '../Label'
import { isWithLabel, isWithAriaLabelledBy } from '../utils'
import { useFocus } from '../useFocus'

export type SelectionInputProps = React.InputHTMLAttributes<HTMLInputElement> &
  TestIdentifiable &
  Labelled & {
    /** Hide the label in the UI (but preserve it in aria-label for accessibility). */
    hideLabel?: boolean
    checked?: boolean
    /** onChange called with event, same as native input api */
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    containerClassName?: string
    checkedIcon: React.ReactElement
    uncheckedIcon: React.ReactElement
    type: 'checkbox' | 'radio'
    subLabel?: string
  }

export const SelectionInput = React.forwardRef<
  HTMLInputElement,
  SelectionInputProps
>(
  (
    {
      checked = false,
      disabled = false,
      hideLabel = false,
      testId,
      onChange,
      onBlur,
      onFocus,
      type,
      checkedIcon,
      uncheckedIcon,
      containerClassName,
      subLabel,
      ...restProps
    },
    ref
  ) => {
    const { focused, ...focusHandlers } = useFocus({ onBlur, onFocus })
    const label = isWithLabel(restProps) && restProps.label
    const ariaLabelledBy =
      isWithAriaLabelledBy(restProps) && restProps['aria-labelledby']

    const id = useUniqueId(restProps.id, 'selection-input-')
    const uniqueTestId = useUniqueId(testId, 'selection-input-')

    useEffect(() => {
      if (label && typeof label !== 'string' && hideLabel) {
        throw new Error(
          "Oops! You can't use a non-string item as a label when hideLabel is true"
        )
      }
    }, [label, hideLabel])

    return (
      <div
        data-testid={`${uniqueTestId}-container`}
        className={cx(
          'flex relative items-start pl-px overflow-visible select-none group',
          containerClassName,
          {
            'cursor-default pointer-events-none': disabled
          }
        )}
      >
        <div
          data-testid={uniqueTestId}
          className={cx(
            'inline-flex w-10 h-10 mr-1 my-0.5 -ml-3 rounded-full box-border',
            'group-hover:bg-darken-4',
            {
              'selection-input': !checked && !disabled,
              'selection-input-selected': checked && !disabled,
              'shadow-focus-inset': focused,
              'border-transparent selection-input-disabled cursor-default':
                disabled
            }
          )}
          // @ts-ignore: `disabled` isn't a property of div, but this may serve a purpose
          disabled={disabled}
        >
          {checked
            ? cloneElement(checkedIcon, {
                ...checkedIcon.props,
                accessibility: 'decorative',
                className: cx(
                  checkedIcon.props && checkedIcon.props.className
                    ? checkedIcon.props.className
                    : 'p-2'
                )
              })
            : cloneElement(uncheckedIcon, {
                ...uncheckedIcon.props,
                accessibility: 'decorative',
                className: cx(
                  uncheckedIcon.props && uncheckedIcon.props.className
                    ? uncheckedIcon.props.className
                    : 'p-2'
                )
              })}
          <input
            ref={ref}
            type={type}
            onChange={onChange}
            className='absolute left-0 w-10 h-10 opacity-0 cursor-pointer'
            data-testid={`${uniqueTestId}-input`}
            checked={checked}
            disabled={disabled}
            id={id}
            aria-label={
              label && hideLabel && typeof label === 'string'
                ? label
                : undefined
            }
            aria-labelledby={ariaLabelledBy ? ariaLabelledBy : undefined}
            {...focusHandlers}
            {...restProps}
          />
        </div>
        {label && (
          <Label
            htmlFor={id}
            disabled={disabled}
            testId={`${uniqueTestId}-label`}
            className={cx('font-normal', {
              'sr-only': hideLabel
            })}
          >
            <div>
              {label}
              {subLabel && (
                <div
                  className={cx('type-subhead', {
                    'text-secondary': !disabled,
                    'text-disabled': disabled
                  })}
                >
                  {subLabel}
                </div>
              )}
            </div>
          </Label>
        )}
      </div>
    )
  }
)

SelectionInput.displayName = 'SelectionInput'
