import { Heap, TrackingEvent } from './types'

export const PREFIX = 'Guest Feedback Public: '

export const trackEvent = (event: TrackingEvent, attempt = 0) => {
  if (window?.heap?.loaded && window?.heap?.track) {
    const eventName = PREFIX + event.name
    window.heap.track(eventName, event.tags)
  } else if (attempt < 2) {
    setTimeout(() => {
      trackEvent(event, attempt + 1)
    }, 500)
  }
}

export const addHeapEventProperties: Heap['addEventProperties'] = (
  properties
) => {
  if (window.heap?.loaded && window?.heap?.addEventProperties) {
    window.heap.addEventProperties(properties)
  }
}
